import React from "react";
import MyReviewList from "./MyReviewList.js";

const MyReview = () => {
  return (
    <>
      <MyReviewList />
    </>
  );
};

export default MyReview;
